import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
    return (
        <div className="loading-container">
            <FaSpinner className="loading-spinner" />
            <span className="loading-text">Loading...</span>
        </div>
    );
};

export default LoadingSpinner;
