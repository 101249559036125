import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaSpinner, FaClock, FaCircle } from 'react-icons/fa';
import './DataBrokerListComponent.css';

const DataBrokerListComponent = ({ 
    dataBrokers = [], 
    isScanning = false, 
    currentSite = null,
    profilesFound = 0 
}) => {
    // Function to generate consistent removal status and time
    const getRemovalStatus = (index) => {
        const statuses = [
            { status: 'Processing Request', color: '#bb86fc', time: '2-4 days' },
            { status: 'Removal Sent', color: '#D8FF60', time: '1-2 days' },
            { status: 'Removal in Progress', color: '#00ccff', time: '2-3 days' },
            { status: 'Verification Required', color: '#ff9900', time: '3-5 days' },
            { status: 'Removal Accepted', color: '#00ff9d', time: '24 hours' }
        ];
        return statuses[index % statuses.length];
    };

    // If no data brokers, show waiting message
    if (!Array.isArray(dataBrokers) || dataBrokers.length === 0) {
        return (
            <div className="databrokerlist-container">
                <div className="no-data">
                    Waiting for next scan.
                </div>
            </div>
        );
    }

    // Determine which brokers to show
    const brokersToShow = isScanning ? 
        dataBrokers.slice(0, Math.max(1, profilesFound)) : 
        dataBrokers.slice(0, Math.max(1, profilesFound));

    return (
        <div className="databrokerlist-container">
            <table className="databrokerlist-table">
                <thead>
                    <tr>
                        <th>Data Broker</th>
                        <th>Status</th>
                        <th>Estimated Time</th>
                    </tr>
                </thead>
                <tbody>
                    {brokersToShow.map((broker, index) => {
                        const removalInfo = getRemovalStatus(index);
                        return (
                            <tr key={index} className={broker.name === currentSite ? 'scanning-row' : ''}>
                                <td data-label="Data Broker">
                                    <span className="broker-name">{broker.name}</span>
                                </td>
                                <td data-label="Status">
                                    {isScanning && broker.name === currentSite ? (
                                        <span className="scanning-status">
                                            <FaSpinner className="spinner-icon" /> Scanning...
                                        </span>
                                    ) : (
                                        <span className="status-badge" style={{ color: removalInfo.color }}>
                                            <FaCircle className="status-icon" /> {removalInfo.status}
                                        </span>
                                    )}
                                </td>
                                <td data-label="Estimated Time">
                                    <span className="removal-time">
                                        <FaClock /> {removalInfo.time}
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

DataBrokerListComponent.propTypes = {
    dataBrokers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        })
    ).isRequired,
    isScanning: PropTypes.bool,
    currentSite: PropTypes.string,
    profilesFound: PropTypes.number,
};

export default DataBrokerListComponent;
