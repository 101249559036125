import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // For adding meta tags
import './PasswordCheckPage.css';
import { 
    FaEye, 
    FaEyeSlash, 
    FaCopy, 
    FaShieldAlt, 
    FaLightbulb, 
    FaVideo, 
    FaInfoCircle, 
    FaCheckCircle 
} from 'react-icons/fa';
import MobileNavbar from '../components/MobileNavbar';
import Navbar from '../components/Navbar';
import useWindowSize from '../hooks/useWindowSize';
import { Footer } from '../components/footer'; // Note the curly braces

const PasswordCheckPage = () => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [attackTypes, setAttackTypes] = useState([]);
    const [bruteForceTime, setBruteForceTime] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [generatedBruteForceTime, setGeneratedBruteForceTime] = useState('');
    const [showInfoCheck, setShowInfoCheck] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { width } = useWindowSize();
    const [safetyScore, setSafetyScore] = useState(0);
    const [copySuccess, setCopySuccess] = useState('');
    const [infoCheckResult, setInfoCheckResult] = useState(null);
    const [isAnalyzing, setIsAnalyzing] = useState(false);

    const guessesPerSecond = 1e12; // 1 trillion guesses per second

    const roundTime = (time) => {
        if (time < 1) {
            return `${(time * 60).toFixed(2)} minutes`;
        }
        if (time < 24) {
            return `${time.toFixed(2)} hours`;
        }
        const days = time / 24;
        if (days < 365) {
            return `${days.toFixed(2)} days`;
        }
        const years = days / 365;
        return `${years.toFixed(2)} years`;
    };

    const isCommonPassword = (pwd) => ['password', '123456', 'qwerty'].includes(pwd.toLowerCase());

    const estimateBruteForceTime = (pwd) => {
        const possibleCombinations = Math.pow(95, pwd.length); 
        const timeInSeconds = possibleCombinations / guessesPerSecond;
        const timeInHours = timeInSeconds / 3600;
        return roundTime(timeInHours);
    };

    const generateSuggestions = (pwd) => {
        const suggestions = [];
        if (pwd.length < 12) suggestions.push('Use at least 12 characters.');
        if (!/[A-Z]/.test(pwd)) suggestions.push('Include uppercase letters.');
        if (!/[a-z]/.test(pwd)) suggestions.push('Include lowercase letters.');
        if (!/[0-9]/.test(pwd)) suggestions.push('Include numbers.');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) suggestions.push('Include special characters.');
        if (isCommonPassword(pwd)) suggestions.push('Avoid using common passwords.');
        return suggestions;
    };

    const generateStrongPassword = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~|}{[]:;?><,./-=";
        let pwd = "";
        for (let i = 0; i < 16; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            pwd += charset[randomIndex];
        }
        return pwd;
    };

    const calculateSafetyScore = (pwd) => {
        let score = 0;
        if (pwd.length >= 15) {
            score += 40;
        } else if (pwd.length >= 11) {
            score += 20;
        } else if (pwd.length >= 8) {
            score += 10;
        }

        let varietyPoints = 0;
        if (/[A-Z]/.test(pwd)) varietyPoints += 10; // Uppercase
        if (/[a-z]/.test(pwd)) varietyPoints += 10; // Lowercase
        if (/[\d]/.test(pwd)) varietyPoints += 10; // Numbers
        if (/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) varietyPoints += 10; // Special Characters
        score += varietyPoints;

        if (!isCommonPassword(pwd)) {
            score += 20;
        }

        if (score > 100) score = 100;

        return score;
    };

    const handleCheckPassword = () => {
        if (!password) {
            setError('Please enter a password.');
            return;
        }
        setError('');
        setIsAnalyzing(true);
        
        // Simulate analysis
        setTimeout(() => {
            const attacks = isCommonPassword(password) ? ['Dictionary Attack', 'Brute Force Attack'] : ['Brute Force Attack'];
            const bruteTime = estimateBruteForceTime(password);
            const pwdSuggestions = generateSuggestions(password);
            const score = calculateSafetyScore(password);

            setAttackTypes(attacks);
            setBruteForceTime(bruteTime);
            setSuggestions(pwdSuggestions);
            setSafetyScore(score);
            setShowInfoCheck(true);
            setIsAnalyzing(false);
        }, 3000); // 3 seconds of "analysis"
    };

    const handleGeneratePassword = () => {
        const strongPwd = generateStrongPassword();
        setGeneratedPassword(strongPwd);
        setGeneratedBruteForceTime(estimateBruteForceTime(strongPwd));
        const score = calculateSafetyScore(strongPwd);
        setSafetyScore(score);
        setShowInfoCheck(true);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(generatedPassword)
            .then(() => {
                setCopySuccess('Password copied to clipboard!');
                setTimeout(() => setCopySuccess(''), 3000);
            })
            .catch(() => {
                setCopySuccess('Failed to copy password.');
                setTimeout(() => setCopySuccess(''), 3000);
            });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const redirectUrl = `https://app.cleandata.me/location?first=${encodeURIComponent(firstName)}&last=${encodeURIComponent(lastName)}`;
        window.location.href = redirectUrl;
    };

    const convertTimeToFun = (time) => {
        const years = parseFloat(time);
        if (years < 1) {
            const days = years * 365;
            return `${days.toFixed(1)} days (about ${Math.ceil(days / 7)} weekends)`;
        }
        if (years < 100) {
            return `${years.toFixed(1)} years (${Math.floor(years)} trips around the sun)`;
        }
        if (years < 1000) {
            return `${years.toFixed(1)} years (${Math.floor(years / 100)} centuries)`;
        }
        return `${years.toFixed(1)} years (${Math.floor(years / 1000)} millennia)`;
    };

    const AnalysisAnimation = () => (
        <div className="analysis-animation">
            <p>Analyzing password strength...</p>
            <div className="progress-bar">
                <div className="progress"></div>
            </div>
        </div>
    );

    return (
        <div className="password-check-page">
            <Helmet>
                <title>Is Your Password Secure? | Password Strength Checker</title>
                <meta name="description" content="Check your password strength with our advanced analyzer. Learn how to create secure passwords and protect your online accounts." />
            </Helmet>

            {/* Navbar */}
            {width > 768 && <Navbar />}
            {width <= 768 && <MobileNavbar />}

            <main className="content-wrapper">
                <section className="header-section">
                    <h1>Is Your Password Secure?</h1>
                    <h2>Top-Rated Password Strength Analyzer Trusted by Millions Worldwide</h2>
                    
                    <div className="password-check-form">
                        <div className="input-group">
                            <div className="password-input-wrapper">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className="password-input"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    aria-label="Password input"
                                />
                                <button
                                    className="toggle-visibility-button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                            <button className="check-button" onClick={handleCheckPassword} disabled={isAnalyzing}>
                                {isAnalyzing ? 'Analyzing...' : 'Check Password'}
                            </button>
                        </div>
                        {isAnalyzing && <AnalysisAnimation />}
                        {error && <div className="error-message">{error}</div>}
                    </div>

                    {/* Analysis Results */}
                    {showInfoCheck && (
                        <div className="analysis-results">
                            <h2><FaShieldAlt className="section-icon" /> Analysis Results</h2>
                            
                            {/* Safety Score Section */}
                            <div className="safety-score-section">
                                <div className="score-label">
                                    <span>Safety Score: {safetyScore}%</span>
                                    <span className="score-icon">
                                        {safetyScore < 50 && <FaInfoCircle title="Weak Password" />}
                                        {safetyScore >= 50 && safetyScore < 75 && <FaInfoCircle title="Moderate Password" />}
                                        {safetyScore >= 75 && <FaInfoCircle title="Strong Password" />}
                                    </span>
                                </div>
                                <div className="strength-label">
                                    {safetyScore < 50 && 'Weak'}
                                    {safetyScore >= 50 && safetyScore < 75 && 'Moderate'}
                                    {safetyScore >= 75 && 'Strong'}
                                </div>
                            </div>

                            {/* Possible Attacks */}
                            <div className="result-box">
                                <h3><FaInfoCircle className="subsection-icon" /> Possible Attacks:</h3>
                                <ul className="result-list">
                                    {attackTypes.map((attack, index) => (
                                        <li key={index} className={`attack-badge ${attack.toLowerCase().replace(' ', '-')}`}>
                                            {attack}
                                            <FaInfoCircle 
                                                className="tooltip-icon" 
                                                title={attack === 'Dictionary Attack' ? 'An attack using a list of common words.' : 'An attack trying all possible combinations.'} 
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <p><strong>Estimated time to crack via brute force:</strong> <span className="crack-time">{convertTimeToFun(bruteForceTime)}</span></p>
                            </div>

                            {/* Suggestions */}
                            {suggestions.length > 0 && (
                                <div className="result-box">
                                    <h3><FaLightbulb className="subsection-icon" /> Suggestions to Improve Your Password:</h3>
                                    <ul className="suggestions-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index}>{suggestion}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}

                    <p className="privacy-statement">
                        Your privacy is our priority. All entries are completely secure, never stored, and never shared. Guaranteed.
                    </p>
                </section>

                <section className="why-strong-passwords-section card">
                    <h2>Why Strong Passwords Are Crucial for Online Security</h2>
                    <p>
                        Passwords are the cornerstone of your digital security. However, our recent research indicates that 
                        less than 50% of Americans feel confident about their password's security. Are you among them? Use our 
                        advanced password checker to evaluate your password's strength and ensure your online safety today!
                    </p>
                    <p><strong>Pro Tip:</strong> Discover our new password generator tool designed to create ultra-secure passwords effortlessly!</p>
                </section>

                <section className="create-secure-password-section card">
                    <h2>How to Create a Secure Password</h2>
                    <p>If your password strength rating isn't satisfactory, it's time to enhance your security with a stronger password. Follow these best practices:</p>
                    <ul className="best-practices-list">
                        <li><strong>Use 12-16 Characters:</strong> Longer passwords are significantly more secure. Our research shows that 
                            45% of Americans use passwords with eight characters or fewer, which are highly vulnerable.
                        </li>
                        <li><strong>Mix Characters:</strong> Combine uppercase and lowercase letters, numbers, and special symbols to increase complexity.</li>
                        <li><strong>Unique Passwords for Each Account:</strong> Avoid using the same password across multiple platforms to minimize risk.</li>
                        <li><strong>Avoid Personal Information:</strong> Do not include easily accessible information like birthdays, addresses, or names of family members and pets.</li>
                        <li><strong>No Sequential Characters:</strong> Steer clear of consecutive letters or numbers (e.g., ABCD, 1234).</li>
                        <li><strong>Avoid Common Passwords:</strong> Words like "password" or repetitive characters are easily guessable and should be avoided.</li>
                    </ul>
                </section>

                <section className="importance-password-security-section card">
                    <h2>The Importance of Password Security</h2>
                    <p>Weak passwords can lead to severe consequences, including:</p>
                    <ul className="consequences-list">
                        <li><strong>Identity Theft:</strong> Hackers can access personal information such as names, addresses, and bank details, leading to financial loss and identity theft.</li>
                        <li><strong>Privacy Breaches:</strong> Unauthorized access to accounts can compromise your privacy, such as hackers accessing your home security cameras.</li>
                        <li><strong>Business Risks:</strong> Data breaches can result in significant financial losses, reputational damage, and loss of market value for businesses.</li>
                    </ul>
                </section>

                <section className="consequences-stolen-passwords-section card">
                    <h2>Consequences of Stolen Passwords</h2>
                    <p>Stolen passwords were responsible for <strong>80% of all data breaches in 2019</strong>, leading to substantial financial losses for both businesses and consumers.</p>
                    
                    <h3>Impact on Businesses:</h3>
                    <ul className="business-impact-list">
                        <li><strong>High Costs:</strong> The average cost of a data breach in the U.S. was <strong>$8.64 million in 2020</strong>.</li>
                        <li><strong>Industry-Specific Risks:</strong> In manufacturing, stolen credentials led to <strong>922 cybersecurity incidents in 2020</strong>, primarily driven by financial motives.</li>
                    </ul>

                    <h3>Impact on Consumers:</h3>
                    <ul className="consumer-impact-list">
                        <li><strong>Financial Losses:</strong> Identity theft resulted in <strong>$92 million in total losses</strong> in 2019, with the median loss per consumer being <strong>$8,946</strong>.</li>
                    </ul>
                </section>

                <section className="additional-security-measures-section card">
                    <h2>Additional Online Security Measures</h2>
                    <p>Beyond strong, unique passwords, consider implementing these best practices to enhance your digital security:</p>
                    <ul className="security-measures-list">
                        <li><strong>Use a VPN:</strong> Protect your online activity and hide your IP address by connecting through a Virtual Private Network.</li>
                        <li><strong>Get Identity Theft Protection:</strong> Utilize top-tier identity theft protection software to monitor and safeguard your personal information.</li>
                        <li><strong>Install Antivirus Software:</strong> Regularly scan your devices for malware, viruses, and other cyber threats.</li>
                        <li><strong>Use a Password Manager:</strong> Securely store and manage your passwords with encrypted vaults, requiring only a master password or biometric authentication.</li>
                        <li><strong>Change Passwords When Necessary:</strong> Update your passwords if you suspect a compromise, rather than on a regular schedule, to maintain their strength.</li>
                    </ul>
                </section>

                <section className="how-works-section card">
                    <h2>How Our Password Checker Works</h2>
                    <p>
                        Our "Is Your Password Secure?" tool evaluates your password against a database of commonly used weak passwords and assesses its strength based on several key factors:
                    </p>
                    <ul className="checker-work-list">
                        <li><strong>Length:</strong> Passwords should ideally be between <strong>16-20 characters</strong>.</li>
                        <li><strong>Character Variety:</strong> A mix of letters, numbers, and symbols enhances security.</li>
                        <li><strong>Uniqueness:</strong> Avoid repetitive or common patterns.</li>
                    </ul>
                    <p>
                        The tool provides a safety score and estimates the time required for a computer to crack your password, helping you understand and improve your password's security.
                    </p>
                </section>

                <section className="faq-section card">
                    <h2>Frequently Asked Questions</h2>
                    <dl itemScope itemType="https://schema.org/FAQPage">
                        <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <dt itemProp="name">What is the most secure password?</dt>
                            <dd itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text">
                                    A highly secure password typically includes a combination of uppercase and lowercase letters, numbers, and special characters, and is at least 16 characters long.
                                </div>
                            </dd>
                        </div>
                        <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <dt itemProp="name">Can you provide an example of a secure password?</dt>
                            <dd itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text">
                                    Sure! An example of a secure password is <strong>bL8%4TO&t9b%</strong>, which is generated by our recommended password manager, LastPass. This password would take approximately <strong>46 million years</strong> for a computer to crack!
                                </div>
                            </dd>
                        </div>
                        <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <dt itemProp="name">How secure is a 12-character password?</dt>
                            <dd itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text">
                                    A 12-character password is considered moderately secure, especially if it includes a mix of different character types. However, longer passwords are more secure.
                                </div>
                            </dd>
                        </div>
                        <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <dt itemProp="name">Are longer passwords more secure?</dt>
                            <dd itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text">
                                    Yes, generally, the longer the password, the more secure it is against brute force attacks.
                                </div>
                            </dd>
                        </div>
                        <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                            <dt itemProp="name">What are the five most common passwords?</dt>
                            <dd itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                <div itemProp="text">
                                    Common passwords often include simple sequences or easily guessable terms like <strong>123456</strong>, <strong>password</strong>, <strong>qwerty</strong>, <strong>123456789</strong>, and <strong>12345678</strong>.
                                </div>
                            </dd>
                        </div>
                    </dl>
                </section>

                <section className="generate-password-instantly-section card">
                    <h2>Generate a Secure Password Instantly</h2>
                    <p>
                        Create a Strong Password Now. Click the button below to generate a highly secure password that meets all the recommended security standards.
                    </p>
                    <button className="generate-password-button" onClick={handleGeneratePassword}>Generate Strong Password</button>
                    {generatedPassword && (
                        <div className="generated-password-box">
                            <p className="generated-password">{generatedPassword}</p>
                            <button className="copy-button" onClick={handleCopy} aria-label="Copy password to clipboard">
                                <FaCopy />
                            </button>
                        </div>
                    )}
                    {copySuccess && <div className="copy-success">{copySuccess}</div>}
                    {generatedBruteForceTime && (
                        <div className="crack-time-display">
                            <p><strong>Estimated time to crack:</strong> <span className="crack-time">{convertTimeToFun(generatedBruteForceTime)}</span></p>
                        </div>
                    )}
                    <p>
                        Once generated, you can copy the password to your clipboard with a single click and see how long it would take for a computer to crack it, ensuring your online accounts remain safe and secure.
                    </p>
                </section>

                <section className="why-strong-passwords-matter-section card">
                    <h2>Why Strong Passwords Matter</h2>
                    <ul className="strong-passwords-list">
                        <li><strong>Enhanced Security:</strong> Strong passwords resist brute force attacks and unauthorized access.</li>
                        <li><strong>Unique Across Platforms:</strong> Prevents a breach on one site from affecting your accounts elsewhere.</li>
                        <li><strong>Avoid Guessable Patterns:</strong> Reduces the risk of your password being easily guessed or cracked.</li>
                        <li><strong>Two-Factor Authentication (2FA):</strong> Adds an extra layer of security beyond just your password.</li>
                        <li><strong>Password Managers:</strong> Simplify the process of managing and generating complex passwords.</li>
                    </ul>
                </section>

                <section className="watch-video-section card">
                    <h2>Watch Our Password Security Video</h2>
                    <p>
                        <strong>Why Use a Password Manager?</strong> Managing multiple strong passwords can be challenging. Password managers securely store, generate, and retrieve complex passwords, making it easier to maintain robust security across all your online accounts.
                    </p>
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/JSQNC3BWGh8?si=V08DZrL0GmqSt9Km"
                            title="Password Manager Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <a className="button-link" href="https://1password.com/" target="_blank" rel="noopener noreferrer">Get 1Password</a>
                </section>

                <section className="info-check-section card">
                    <h2>Check If Your Information Is Compromised</h2>
                    <p>Enter your first and last name below to check if your data has been exposed in any breaches.</p>
                    <form onSubmit={handleFormSubmit}>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            aria-label="First Name"
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            aria-label="Last Name"
                        />
                        <button type="submit" className="check-info-button">Check Now</button>
                    </form>
                    {infoCheckResult && (
                        <div className={`info-check-result ${infoCheckResult.success ? 'success' : 'error'}`}>
                            {infoCheckResult.message}
                        </div>
                    )}
                </section>
            </main>

            <Footer />
        </div>
    );
};

export default PasswordCheckPage;
