import React, { useState, useEffect } from 'react';
import './ChatPage.css';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import { Footer } from '../components/footer';

const apiKey = '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'; // Replace with your actual OpenAI API key

const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [showWelcome, setShowWelcome] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [thinkingStage, setThinkingStage] = useState(''); // State to handle thinking stages

    // Update the state based on window resize for responsive Navbar
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const preMadeQuestions = [
        'I received an email and I don\'t know if it\'s legitimate.',
        'Someone wants to buy my item but wants to pay by cheque. Am I being scammed?',
        'I got a call claiming to be from the IRS. Should I trust it?',
        'I was told I won a lottery that I didn\'t enter. Is this a scam?',
        'An online seller is offering a deal that seems too good to be true. Should I proceed?',
        'I received a job offer that requires me to pay upfront fees. Is this legitimate?'
    ];

    const scamKeywords = ['lottery', 'cheque', 'IRS', 'fees', 'money', 'bank details', 'password', 'credit card', 'wire transfer'];

    const highlightScamKeywords = (text) => {
        let highlightedText = text;
        scamKeywords.forEach((keyword) => {
            const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
            highlightedText = highlightedText.replace(regex, `<span class="highlight">${keyword}</span>`);
        });
        return highlightedText;
    };

    const sendMessage = async (message) => {
        try {
            setThinkingStage('Thinking...');
            await new Promise(resolve => setTimeout(resolve, 500)); // Simulate delay for stages

            setThinkingStage('Analyzing Scam Threats...');
            await new Promise(resolve => setTimeout(resolve, 500));

            setThinkingStage('Preparing Response...');
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "system",
                            content: "You are a scam detection expert helping users determine whether they are being scammed. Offer advice and suggestions based on the user's query."
                        },
                        {
                            role: "user",
                            content: message
                        }
                    ],
                    max_tokens: 500, // Increased token limit
                })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            return formatResponse(data.choices[0].message.content.trim()); // Use formatResponse to handle text
        } catch (error) {
            setThinkingStage(''); // Clear the stages
            console.error('Error fetching GPT response:', error);
            return 'There was an error processing your request. Please try again later.';
        }
    };

    // Format the response for better structure
    const formatResponse = (responseText) => {
        return responseText
            .replace(/\n/g, '<br />') // Replace newlines with HTML line breaks
            .replace(/(\d+)\./g, '<br /><strong>$1.</strong>') // Bold the list numbers
            .replace(/(\*\*)(.+?)(\*\*)/g, '<strong>$2</strong>'); // Convert **bold** to <strong>bold</strong>
    };

    // Gradually display text like a typing effect
    const typeWriterEffect = (text, speed = 30) => { // Speed set to faster
        return new Promise((resolve) => {
            let index = 0;
            const interval = setInterval(() => {
                if (index < text.length) {
                    setMessages((prevMessages) => {
                        const lastMessage = prevMessages[prevMessages.length - 1];
                        const newContent = lastMessage.content + text.charAt(index);
                        const updatedMessages = [...prevMessages];
                        updatedMessages[updatedMessages.length - 1] = { ...lastMessage, content: newContent };
                        return updatedMessages;
                    });
                    index++;
                } else {
                    clearInterval(interval);
                    setThinkingStage(''); // Clear the thinking stages
                    resolve();
                }
            }, speed);
        });
    };

    const handleSendMessage = async () => {
        if (userInput.trim() === "") return;

        const newMessages = [...messages, { role: "user", content: userInput }];
        setMessages(newMessages);
        setUserInput('');
        setShowWelcome(false);

        // Show the thinking stage
        setMessages((prevMessages) => [
            ...prevMessages,
            { role: "assistant", content: '' }
        ]);

        const botResponse = await sendMessage(userInput);

        await typeWriterEffect(botResponse);
    };

    const handlePreMadeQuestionClick = async (question) => {
        const newMessages = [...messages, { role: "user", content: question }];
        setMessages(newMessages);
        setShowWelcome(false);

        // Show the thinking stage
        setMessages((prevMessages) => [
            ...prevMessages,
            { role: "assistant", content: '' }
        ]);

        const botResponse = await sendMessage(question);

        await typeWriterEffect(botResponse);
    };

    return (
        <div className="chat-page">
            <div className="navbar-wrapper">
                {isMobile ? <MobileNavbar /> : <Navbar />}
            </div>
            
            <div className="chat-container">
                <div className="chat-history">
                    {showWelcome && messages.length === 0 && (
                        <div className="welcome-message">
                            <h1>Protect Yourself from Scams</h1>
                            <p>Ask any question about suspicious activities, and we'll help you determine if it's a scam and how to stay safe.</p>
                            <div className="pre-made-questions">
                                {preMadeQuestions.map((question, index) => (
                                    <button key={index} onClick={() => handlePreMadeQuestionClick(question)} className="question-button">
                                        {question}
                                    </button>
                                ))}
                            </div>
                            <div className="branding">
                                <p>Scam<span className="small-text">AI</span> by CleanData<span className="small-text">.me</span></p>
                            </div>
                        </div>
                    )}
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={message.role === "user" ? "user-message" : "bot-response"}
                            dangerouslySetInnerHTML={{
                                __html:
                                    message.role === "assistant"
                                        ? highlightScamKeywords(message.content)
                                        : message.content,
                            }}
                        />
                    ))}
                    {thinkingStage && (
                        <div className="thinking-stage">
                            {thinkingStage}
                        </div>
                    )}
                </div>
                <div className="input-area">
                    <input
                        type="text"
                        className="input-focus"
                        value={userInput}
                        placeholder="Type your question here..."
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    />
                    <button className="send-button" onClick={handleSendMessage}>
                        Send
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ChatPage;
