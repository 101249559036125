// javascript:src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getUser, login as authLogin, logout as authLogout } from '../services/authService';

const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const isDevelopment = process.env.NODE_ENV === 'development';

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const userData = await getUser();
                if (userData) {
                    setUser(userData);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();
    }, []);

    const login = async (email, password) => {
        try {
            setLoading(true);
            const { user } = await authLogin(email, password);
            setUser(user);
            return user;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        try {
            await authLogout();
            setUser(null);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const value = {
        user,
        loading,
        error,
        login,
        logout,
        isAuthenticated: isDevelopment || !!user,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;