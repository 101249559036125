import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    FaUser, FaEnvelope, FaPhone, FaHome, FaGlobe, FaCity, 
    FaMapPin, FaLock, FaInfoCircle, FaShieldAlt, FaPlus, 
    FaTimes, FaCheck, FaExclamationTriangle 
} from 'react-icons/fa';
import './EditInfoPage.css';

const countriesWithStates = [
    'United States',
    'Canada',
    'Australia',
    'India',
    'Brazil',
    'Mexico',
    'Germany',
    'China',
    'Russia'
];

const EditInfoPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        additionalEmails: [],
        additionalPhones: [],
        address: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
    });

    const [currentSection, setCurrentSection] = useState('personal');
    const [sectionProgress, setSectionProgress] = useState({
        personal: { visited: true, completed: false },
        contact: { visited: false, completed: false },
        address: { visited: false, completed: false }
    });
    
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [formStatus, setFormStatus] = useState('unsubmitted');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [formSection, setFormSection] = useState('personal'); // ['personal', 'contact', 'address']
    const [formProgress, setFormProgress] = useState({
        personal: { completed: false, valid: false },
        contact: { completed: false, valid: false },
        address: { completed: false, valid: false }
    });

    const [options, setOptions] = useState({
        states: [],
        cities: []
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                setIsLoading(true);
                const response = await fetch('https://cleandata.fly.dev/api/user-data', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) throw new Error('Failed to fetch user data');

                const user = await response.json();
                setFormData(prevData => ({
                    ...prevData,
                    email: user.email || '',
                    firstName: user.first_name || '',
                    lastName: user.last_name || '',
                }));

                // Automatically move to contact section if personal info is complete
                if (user.first_name && user.last_name) {
                    setFormSection('contact');
                    setSectionProgress(prev => ({
                        ...prev,
                        personal: { visited: true, completed: true }
                    }));
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, [navigate]);

    useEffect(() => {
        if (formData.country) {
            handleCountryChange({ target: { value: formData.country } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateSection = (section) => {
        const newErrors = {};
        switch(section) {
            case 'personal':
                if (!formData.firstName) newErrors.firstName = 'First name is required';
                if (!formData.lastName) newErrors.lastName = 'Last name is required';
                break;
            case 'contact':
                if (!formData.email) newErrors.email = 'Email is required';
                if (!/^\S+@\S+\.\S+$/.test(formData.email)) newErrors.email = 'Invalid email format';
                break;
            // ... other validation cases
        }
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        // Live validation
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    };

    const handleCountryChange = async (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            country: value,
            state: '',
            city: ''
        }));
        setOptions({ states: [], cities: [] });
        setLoadingOptions(true);

        try {
            if (countriesWithStates.includes(value)) {
                // Fetch states
                const response = await fetch('https://countriesnow.space/api/v0.1/countries/states', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ country: value }),
                });

                if (!response.ok) throw new Error('Error fetching states');

                const result = await response.json();
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    states: result.data.states.map(state => state.name)
                }));
            } else {
                // Fetch cities
                const response = await fetch('https://countriesnow.space/api/v0.1/countries/cities', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ country: value }),
                });

                if (!response.ok) throw new Error('Error fetching cities');

                const result = await response.json();
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    cities: result.data
                }));
            }
        } catch (error) {
            console.error('Error fetching options:', error);
            setOptions({ states: [], cities: [] });
        } finally {
            setLoadingOptions(false);
        }
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemoveField = (field, index) => {
        setFormData((prevData) => {
            const updatedFields = [...prevData[field]];
            updatedFields.splice(index, 1);
            return { ...prevData, [field]: updatedFields };
        });
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[`${field}_${index}`];
            return updatedErrors;
        });
    };

    const handleAdditionalChange = (field, index, value) => {
        const updatedFields = [...formData[field]];
        updatedFields[index] = value;
        setFormData({ ...formData, [field]: updatedFields });

        // Live validation
        setErrors((prevErrors) => ({ ...prevErrors, [`${field}_${index}`]: undefined }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!acceptedTerms) {
            setErrors({ terms: 'You must accept the Terms and Privacy Policy' });
            return;
        }

        setIsSubmitting(true);
        setFormStatus('unsubmitted');

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                window.location.href = '/login';
                return;
            }

            const response = await fetch('https://cleandata.fly.dev/api/submit-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                throw new Error('Form submission error');
            }

            setFormStatus('submitted');
            
            // Show success message briefly before redirect
            setTimeout(() => {
                window.location.href = '/dashboard';
            }, 1500);
        } catch (error) {
            console.error('Form submission error:', error);
            setFormStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleNextSection = () => {
        const currentSectionValid = validateSection(formSection);
        if (Object.keys(currentSectionValid).length === 0) {
            setFormProgress(prev => ({
                ...prev,
                [formSection]: { completed: true, valid: true }
            }));
            
            switch(formSection) {
                case 'personal':
                    setFormSection('contact');
                    break;
                case 'contact':
                    setFormSection('address');
                    break;
                case 'address':
                    handleSubmit();
                    break;
                default:
                    break;
            }
        } else {
            setErrors(currentSectionValid);
        }
    };

    const renderAdditionalEmails = () => (
        <div className="additional-fields-container">
            {formData.additionalEmails.map((email, index) => (
                <div key={`email-${index}`} className="additional-field">
                    <div className="form-field">
                        <label htmlFor={`additional-email-${index}`}>
                            <FaEnvelope className="field-icon" />
                            Additional Email {index + 1}
                        </label>
                        <div className="input-with-action">
                            <input
                                type="email"
                                id={`additional-email-${index}`}
                                value={email}
                                onChange={(e) => handleAdditionalChange('additionalEmails', index, e.target.value)}
                                className={errors[`additionalEmails_${index}`] ? 'error' : ''}
                            />
                            <button 
                                type="button" 
                                className="remove-field"
                                onClick={() => handleRemoveField('additionalEmails', index)}
                            >
                                <FaTimes />
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const renderAddressSection = () => (
        <div className="form-section">
            <h2>Address Information</h2>
            <div className="address-fields">
                <div className="form-field full-width">
                    <label htmlFor="address">
                        <FaHome className="field-icon" />
                        Street Address
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className={errors.address ? 'error' : ''}
                        placeholder="Enter your street address"
                    />
                </div>
                
                <div className="select-container">
                    <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleCountryChange}
                        className={errors.country ? 'error' : ''}
                        disabled={loadingOptions}
                    >
                        <option value="">Select Country</option>
                        {countriesWithStates.map(country => (
                            <option key={country} value={country}>{country}</option>
                        ))}
                    </select>
                </div>

                {formData.country && (
                    <div className="location-fields-wrapper">
                        <div className="select-container">
                            <select
                                id="state"
                                name="state"
                                value={formData.state}
                                onChange={handleStateChange}
                                className={errors.state ? 'error' : ''}
                                disabled={loadingOptions || !options.states.length}
                            >
                                <option value="">Select State</option>
                                {options.states.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>

                        <div className="select-container">
                            <select
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                className={errors.city ? 'error' : ''}
                                disabled={loadingOptions || !options.cities.length}
                            >
                                <option value="">Select City</option>
                                {options.cities.map(city => (
                                    <option key={city} value={city}>{city}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    const renderFormSection = () => {
        switch(formSection) {
            case 'personal':
                return (
                    <div className="form-section">
                        <h2>
                            <FaUser className="section-icon" />
                            Personal
                        </h2>
                        <div className="input-group">
                            <div className="form-field">
                                <label htmlFor="firstName">
                                    <FaUser className="field-icon" />
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className={errors.firstName ? 'error' : ''}
                                />
                                {errors.firstName && <span className="error-message">{errors.firstName}</span>}
                            </div>
                            <div className="form-field">
                                <label htmlFor="lastName">
                                    <FaUser className="field-icon" />
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className={errors.lastName ? 'error' : ''}
                                />
                                {errors.lastName && <span className="error-message">{errors.lastName}</span>}
                            </div>
                        </div>
                    </div>
                );

            case 'contact':
                return (
                    <div className="form-section">
                        <h2>
                            <FaEnvelope className="section-icon" />
                            Contact
                        </h2>
                        <div className="input-group">
                            <div className="form-field">
                                <label htmlFor="email">
                                    <FaEnvelope className="field-icon" />
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={errors.email ? 'error' : ''}
                                />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>
                            <div className="form-field">
                                <label htmlFor="phoneNumber">
                                    <FaPhone className="field-icon" />
                                    Phone Number
                                </label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className={errors.phoneNumber ? 'error' : ''}
                                />
                                {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
                            </div>
                            <div className="additional-fields">
                                <button type="button" onClick={() => handleAddField('additionalEmails')} className="add-button">
                                    <FaPlus /> Add Another Email
                                </button>
                            </div>
                        </div>
                    </div>
                );

            case 'address':
                return (
                    <div className="form-section">
                        <h2>
                            <FaHome className="section-icon" />
                            Address
                        </h2>
                        <div className="input-group">
                            <div className="form-field full-width">
                                <label htmlFor="address">
                                    <FaHome className="field-icon" />
                                    Street Address
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className={errors.address ? 'error' : ''}
                                    placeholder="Enter your street address"
                                />
                            </div>
                            
                            <div className="select-container">
                                <select
                                    id="country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleCountryChange}
                                    className={errors.country ? 'error' : ''}
                                    disabled={loadingOptions}
                                >
                                    <option value="">Select Country</option>
                                    {countriesWithStates.map(country => (
                                        <option key={country} value={country}>{country}</option>
                                    ))}
                                </select>
                            </div>

                            {formData.country && (
                                <div className="location-fields-wrapper">
                                    <div className="select-container">
                                        <select
                                            id="state"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleStateChange}
                                            className={errors.state ? 'error' : ''}
                                            disabled={loadingOptions || !options.states.length}
                                        >
                                            <option value="">Select State</option>
                                            {options.states.map(state => (
                                                <option key={state} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="select-container">
                                        <select
                                            id="city"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            className={errors.city ? 'error' : ''}
                                            disabled={loadingOptions || !options.cities.length}
                                        >
                                            <option value="">Select City</option>
                                            {options.cities.map(city => (
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const handleStateChange = async (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            state: value,
            city: ''
        }));
        setLoadingOptions(true);

        try {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries/state/cities', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    country: formData.country,
                    state: value
                }),
            });

            if (!response.ok) throw new Error('Error fetching cities');

            const result = await response.json();
            if (result.data) {
                setOptions(prev => ({
                    ...prev,
                    cities: result.data.sort()
                }));
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
            setOptions(prev => ({
                ...prev,
                cities: []
            }));
        } finally {
            setLoadingOptions(false);
        }
    };

    // Main loading spinner
    const LoadingSpinner = () => (
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
    );

    // Country selection loading
    const CountryLoading = () => (
        <span className="loading-text">Loading...</span>
    );

    // Only show terms at the end
    const renderTermsSection = () => (
        formSection === 'address' && (
            <div className="terms-section">
                <div className="terms-checkbox">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(e.target.checked)}
                    />
                    <label htmlFor="terms">
                        I agree to the <button type="button" onClick={() => setShowPrivacyModal(true)}>Terms & Privacy Policy</button>
                    </label>
                </div>
                {errors.terms && <span className="error-message">{errors.terms}</span>}
            </div>
        )
    );

    const handleSectionChange = (section) => {
        // Only allow moving to sections that are available or completed
        const sectionOrder = ['personal', 'contact', 'address'];
        const currentIndex = sectionOrder.indexOf(formSection);
        const targetIndex = sectionOrder.indexOf(section);

        if (targetIndex <= currentIndex || sectionProgress[section].completed) {
            setFormSection(section);
            setSectionProgress(prev => ({
                ...prev,
                [section]: { ...prev[section], visited: true }
            }));
        }
    };

    const handlePrevious = () => {
        switch(formSection) {
            case 'contact':
                setFormSection('personal');
                break;
            case 'address':
                setFormSection('contact');
                break;
            default:
                break;
        }
    };

    const handleNext = () => {
        const currentSectionValid = validateSection(formSection);
        if (Object.keys(currentSectionValid).length === 0) {
            setSectionProgress(prev => ({
                ...prev,
                [formSection]: { visited: true, completed: true }
            }));
            
            switch(formSection) {
                case 'personal':
                    setFormSection('contact');
                    break;
                case 'contact':
                    setFormSection('address');
                    break;
                default:
                    break;
            }
        } else {
            setErrors(currentSectionValid);
        }
    };

    return (
        <div className="edit-info-page">
            {isLoading && <LoadingSpinner />}
            <div className="edit-info-container">
                <div className="security-banner">
                    <FaShieldAlt className="shield-icon" />
                    <span>256-bit SSL Encrypted & GDPR Compliant</span>
                </div>
                
                <nav className="form-progress">
                    {['Personal', 'Contact', 'Address'].map((section) => (
                        <button
                            key={section}
                            className={`progress-step ${
                                formSection === section.toLowerCase() ? 'active' : ''
                            } ${
                                sectionProgress[section.toLowerCase()].completed ? 'completed' : ''
                            }`}
                            onClick={() => handleSectionChange(section.toLowerCase())}
                            disabled={!sectionProgress[section.toLowerCase()].completed && formSection !== section.toLowerCase()}
                        >
                            {section}
                        </button>
                    ))}
                </nav>

                <form onSubmit={handleSubmit} className="edit-info-form">
                    {renderFormSection()}
                    {renderTermsSection()}
                    
                    <div className="form-navigation">
                        {formSection !== 'personal' && (
                            <button 
                                type="button" 
                                className="prev-button"
                                onClick={handlePrevious}
                            >
                                Previous
                            </button>
                        )}
                        
                        <button 
                            type="button" 
                            className={`next-button ${formSection === 'address' ? 'submit-button' : ''}`}
                            onClick={formSection === 'address' ? handleSubmit : handleNext}
                            disabled={formSection === 'address' && !acceptedTerms}
                        >
                            {formSection === 'address' ? 'Submit' : 'Next'}
                        </button>
                    </div>
                </form>
            </div>
            
            {showPrivacyModal && (
                <div className="privacy-modal">
                    {/* Privacy policy content */}
                </div>
            )}
        </div>
    );
}

export default EditInfoPage;
