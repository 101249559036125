import React from 'react';
import { FaUser, FaCalendar, FaTag } from 'react-icons/fa';
import './UserInfo.css';

const UserInfo = ({ user }) => {
    console.log('UserInfo component - user data:', user);

    const formatDate = (dateString) => {
        if (!dateString) {
            return 'Not available';
        }

        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                return 'Not available';
            }

            return date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Not available';
        }
    };

    const calculateSubscriptionStatus = (memberSince, currentStatus) => {
        // If there's already a status, use it
        if (currentStatus && currentStatus !== 'trial') {
            return currentStatus;
        }

        if (!memberSince) {
            return 'trial';
        }

        try {
            const joinDate = new Date(memberSince);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - joinDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            
            return diffDays > 7 ? 'premium' : 'trial';
        } catch (error) {
            console.error('Error calculating subscription status:', error);
            return 'trial';
        }
    };

    const subscriptionStatus = calculateSubscriptionStatus(
        user?.memberSince,
        user?.subscriptionStatus
    );

    return (
        <div className="user-info">
            <h3 className="user-info-header">Account Info</h3>
            <div className="user-info-content">
                <div className="info-item">
                    <div className="info-label">
                        <FaUser /> Email
                    </div>
                    <div className="info-value user-email">
                        {user?.email || 'Not available'}
                    </div>
                </div>

                <div className="info-item">
                    <div className="info-label">
                        <FaCalendar /> Member since
                    </div>
                    <div className="info-value">
                        {formatDate(user?.memberSince)}
                    </div>
                </div>

                <div className="info-item">
                    <div className="info-label">
                        <FaTag /> Status
                    </div>
                    <div className={`status-badge ${subscriptionStatus.toLowerCase()}`}>
                        {subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;