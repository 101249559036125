import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ScanningPage.css';
import { fetchMatchProbability } from '../fetchMatchProbability';
import {
    FaExclamationTriangle,
    FaSearch,
    FaShieldAlt,
    FaDatabase,
    FaInfoCircle,
    FaCheckCircle,
    FaBug,
    FaSpinner,
} from 'react-icons/fa';
import peopleSearchSites from './peopleSearchSites';

const auth = 'YOUR_THUMIO_AUTH_KEY';

// Utility function for delays
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Scan stages
const scanStages = [
    {
        stage: 'initializing',
        messages: [
            'Initializing secure scan...',
            'Setting up secure connection...',
            'Preparing scan parameters...',
        ],
    },
    {
        stage: 'searching',
        messages: [
            'Scanning data brokers...',
            'Checking social media platforms...',
            'Analyzing public records...',
            'Searching people finder sites...',
            'Scanning background check services...',
            'Checking address databases...',
        ],
    },
    {
        stage: 'analyzing',
        messages: [
            'Analyzing potential matches...',
            'Verifying data sources...',
            'Processing results...',
            'Compiling findings...',
        ],
    },
];

// Add these constants at the top of your file
const SCAN_DURATION = 24000; // 24 seconds total scan time
const UPDATE_INTERVAL = 500; // Update every 500ms

// Helper function to shuffle arrays
const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};

// Function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Main ScanningPage Component
const ScanningPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { firstName, lastName, city, country } = location.state || {};

    // State Variables
    const [foundThreats, setFoundThreats] = useState([]);
    const [matchProbability, setMatchProbability] = useState(null);
    const [progress, setProgress] = useState(0);
    const [currentScreenshot, setCurrentScreenshot] = useState(null);
    const [currentStageIndex, setCurrentStageIndex] = useState(0);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [error, setError] = useState('');
    const [isScanning, setIsScanning] = useState(true);
    const [totalSites, setTotalSites] = useState(0);
    const headerRef = useRef(null);
    const [currentSite, setCurrentSite] = useState(null);
    const [obfuscatedSiteNames, setObfuscatedSiteNames] = useState({});

    const capitalizedFirstName = capitalizeFirstLetter(firstName);
    const capitalizedLastName = capitalizeFirstLetter(lastName);

    // Define obfuscateWebsiteName inside component
    const obfuscateWebsiteName = (name) => {
        // Handle special cases first
        if (name.includes('.')) {
            const parts = name.split('.');
            const domain = parts[0];
            const extension = parts.slice(1).join('.');
            
            // Special handling for numeric domains
            if (/^\d+$/.test(domain)) {
                return `${domain}.${extension}`; // Keep numeric domains as is
            }
            
            // Handle domain part
            let obfuscatedDomain;
            if (domain.length <= 4) {
                obfuscatedDomain = domain; // Keep short domains as is
            } else {
                obfuscatedDomain = domain.slice(0, 2) + '*'.repeat(domain.length - 4) + domain.slice(-2);
            }
            
            return `${obfuscatedDomain}.${extension}`;
        }
        
        // Handle non-domain names
        if (name.length <= 4) return name;
        return name.slice(0, 2) + '*'.repeat(name.length - 4) + name.slice(-2);
    };

    // Pre-generate obfuscated names when component mounts
    useEffect(() => {
        const obfuscatedMap = peopleSearchSites.reduce((acc, site) => {
            acc[site] = obfuscateWebsiteName(site);
            return acc;
        }, {});
        setObfuscatedSiteNames(obfuscatedMap);
    }, []); // Empty dependency array means this runs once at mount

    // Effect to handle stage progression
    useEffect(() => {
        if (!isScanning) return;

        const messageInterval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => {
                const currentStage = scanStages[currentStageIndex];
                if (prevIndex >= currentStage.messages.length - 1) {
                    // Move to next stage
                    if (currentStageIndex < scanStages.length - 1) {
                        setCurrentStageIndex((prev) => prev + 1);
                        return 0;
                    }
                }
                return (prevIndex + 1) % currentStage.messages.length;
            });
        }, 2000); // Change message every 2 seconds

        return () => clearInterval(messageInterval);
    }, [currentStageIndex, isScanning]);

    // Fetch and set the screenshot URLs and shuffled sites
    useEffect(() => {
        const initializeScan = async () => {
            try {
                if (!firstName || !lastName || !city || !country) {
                    setError('Missing required information. Please go back and provide your details.');
                    return;
                }

                // Generate URLs first
                const urls = generateScreenshotSites(firstName, lastName);

                // Initialize sites for scanning
                const sites = urls.map((site) => ({
                    name: site.siteName,
                    url: site.url,
                }));
                setTotalSites(sites.length);

                // Fetch match probability
                const probability = await fetchMatchProbability(firstName, lastName, city, country);
                setMatchProbability(probability);

                // Start the scan
                await scanSites(probability, sites);
            } catch (error) {
                console.error('Error initializing scan:', error);
                setError('Failed to initialize scan. Please try again.');
            }
        };

        initializeScan();
    }, [firstName, lastName, city, country]);

    const scanSites = async (probability, sites) => {
        setIsScanning(true);
        const startTime = Date.now();
        let elapsedTime = 0;

        // Early exit if minimal risk
        if (probability === 0) {
            setIsScanning(false);
            setProgress(100);
            setFoundThreats([]);
            navigate('/results', {
                state: {
                    threats: [],
                    firstName: firstName,
                    lastName: lastName,
                    totalMatches: 0,
                    riskScore: 0,
                    threatPercentage: '0%',
                    categoryDistribution: {}
                }
            });
            return;
        }

        // Use the match ranges based on risk score to determine total matches
        const riskScore = Math.ceil(probability * 5);
        const baseMatches = {
            5: { min: 35, max: 45 },  // High risk
            4: { min: 25, max: 35 },  // Moderate-high risk
            3: { min: 15, max: 25 },  // Moderate risk
            2: { min: 8, max: 15 },   // Low-moderate risk
            1: { min: 3, max: 8 },    // Low risk
            0: { min: 0, max: 3 }     // Minimal risk
        };

        const { min, max } = baseMatches[riskScore] || baseMatches[3];
        const totalMatches = Math.floor(Math.random() * (max - min + 1)) + min;

        // Define category distribution based on totalMatches
        const categoryDistribution = {
            'Background Check': Math.max(1, Math.ceil(totalMatches * 0.08)),
            'Public Records': Math.max(1, Math.ceil(totalMatches * 0.16)),
            'People Search': Math.max(1, Math.ceil(totalMatches * 0.16)),
            'Social Media': Math.max(1, Math.ceil(totalMatches * 0.16)),
            'Contact Info': Math.max(1, Math.ceil(totalMatches * 0.16)),
            'Address History': Math.max(1, Math.ceil(totalMatches * 0.28))
        };

        // Generate threats based on category distribution
        let threats = [];
        Object.entries(categoryDistribution).forEach(([category, count]) => {
            for (let i = 0; i < count; i++) {
                const shuffledSites = shuffleArray([...peopleSearchSites]);
                threats.push({
                    siteName: obfuscatedSiteNames[shuffledSites[i]] || obfuscateWebsiteName(shuffledSites[i]),
                    category: category,
                    details: `Found personal information exposure`
                });
            }
        });

        // Shuffle the threats for randomness
        threats = shuffleArray(threats);
        
        // Show threats gradually over the remaining time
        const remainingDuration = SCAN_DURATION - 5000;
        const intervalTime = remainingDuration / threats.length;

        for (let i = 0; i <= threats.length && isScanning; i++) {
            const progress = Math.min((i / threats.length) * 100, 100);
            setProgress(progress);
            setFoundThreats(threats.slice(0, i));
            await delay(intervalTime);
        }

        setIsScanning(false);
        setProgress(100);
        setFoundThreats(threats);

        // Navigate to results with the actual data
        navigate('/results', {
            state: {
                threats: threats,
                firstName: firstName,
                lastName: lastName,
                totalMatches: totalMatches, // Use the actual total from GPT analysis
                riskScore: Math.ceil(probability * 100),
                threatPercentage: `${(probability * 100).toFixed(2)}%`,
                categoryDistribution: categoryDistribution
            }
        });
    };

    // Verify that required data is available
    useEffect(() => {
        if (!location.state) {
            console.error('No location state found');
            setError('Missing required information. Please go back and try again.');
            return;
        }

        if (!firstName || !lastName || !city || !country) {
            console.error('Missing required fields:', { firstName, lastName, city, country });
            setError('Missing required information. Please go back and provide your details.');
            return;
        }
    }, [location.state, firstName, lastName, city, country]);

    // Add this new useEffect for scrolling
    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current && document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'TEXTAREA') {
                headerRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        };

        handleScroll();

        // Optionally, add event listeners if you want to handle dynamic changes
        // window.addEventListener('focusin', handleScroll);
        // window.addEventListener('focusout', handleScroll);

        return () => {
            // window.removeEventListener('focusin', handleScroll);
            // window.removeEventListener('focusout', handleScroll);
        };
    }, []);

    // Function for screenshot sites (top section visual display)
    const generateScreenshotSites = (firstName, lastName) => {
        return [
            { url: `https://dataveria.com/profile/search?fname=${firstName}&lname=${lastName}`, siteName: 'Dataveria.com' },
            { url: `https://411.info/people?fn=${firstName}&ln=${lastName}`, siteName: '411.info' },
            { url: `https://www.anywho.com/people/${firstName}%20+${lastName}/`, siteName: 'AnyWho.com' },
            // Add more screenshot sites as needed
        ];
    };

    // Generate screenshot URL using Thum.io
    const generateScreenshotUrl = (url) => {
        try {
            const encodedUrl = encodeURIComponent(url);
            return `https://image.thum.io/get/auth/${auth}/width/800/crop/600/${encodedUrl}`;
        } catch (error) {
            console.error('URL generation error:', error);
            return null;
        }
    };

    // ScreenshotSection Component
    const ScreenshotSection = ({ currentScreenshot }) => {
        const [isLoading, setIsLoading] = useState(true);
        const [loadError, setLoadError] = useState(false);

        useEffect(() => {
            if (currentScreenshot) {
                setIsLoading(true);
                setLoadError(false);
            }
        }, [currentScreenshot]);

        if (!currentScreenshot) {
            return (
                <div className="screenshot-section">
                    <h3>
                        <FaSearch />
                        Live Scan Results
                    </h3>
                    <div className="screenshot-container">
                        <div className="screenshot-placeholder">
                            <div className="cyber-spinner"></div>
                            <p>Initializing scan...</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="screenshot-section">
                <h3>
                    <FaSearch />
                    Live Scan Results
                </h3>
                <div className="screenshot-container">
                    <div className={`image-preview ${isLoading ? 'loading' : ''} ${loadError ? 'error' : ''}`}>
                        <div className="preview-content">
                            {isLoading && (
                                <div className="loading-state">
                                    <div className="cyber-spinner"></div>
                                    <span>Scanning {currentScreenshot.siteName}</span>
                                </div>
                            )}

                            <img
                                src={generateScreenshotUrl(currentScreenshot.url)}
                                alt={`${currentScreenshot.siteName} Analysis`}
                                className={`preview-image ${isLoading ? 'hidden' : ''}`}
                                onLoad={() => setIsLoading(false)}
                                onError={() => {
                                    setLoadError(true);
                                    setIsLoading(false);
                                }}
                            />

                            {!isLoading && !loadError && (
                                <div className="cyber-overlay">
                                    <div className="live-indicator">
                                        <div className="recording-dot"></div>
                                        <span>LIVE SCAN</span>
                                    </div>
                                    <div className="site-info">
                                        <span className="site-name">{currentScreenshot.siteName}</span>
                                    </div>
                                </div>
                            )}

                            {loadError && (
                                <div className="error-state">
                                    <FaExclamationTriangle className="error-icon" />
                                    <span>Failed to load screenshot.</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Function to format threat messages
    const formatThreatMessage = (siteName, category) => {
        const exposureTypes = {
            'Background Check': {
                icon: '🔍',
                details: 'Possible background information available',
            },
            'Public Records': {
                icon: '📄',
                details: 'Potential public record information identified',
            },
            'People Search': {
                icon: '👤',
                details: 'Possible personal information available',
            },
            'Social Media': {
                icon: '📱',
                details: 'Social media presence detected',
            },
            'Contact Info': {
                icon: '📞',
                details: 'Contact details may be exposed',
            },
            'Address History': {
                icon: '🏠',
                details: 'Possible address history found',
            },
        };

        const exposureType = exposureTypes[category] || exposureTypes['People Search'];

        const obfuscatedName = obfuscateWebsiteName(siteName);

        return {
            icon: exposureType.icon,
            category: category,
            siteName: obfuscatedName,
            details: exposureType.details,
        };
    };

    // Use useRef for values that shouldn't trigger re-renders
    const scrollRef = useRef(0);
    const foundThreatsRef = useRef([]);
    
    // Memoize the threats to prevent unnecessary re-renders
    const memoizedThreats = useMemo(() => {
        return foundThreatsRef.current;
    }, [foundThreatsRef.current.length]);

    // Handle scroll events without causing re-renders
    const handleScroll = useCallback((e) => {
        scrollRef.current = window.scrollY;
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    // Update threats without causing re-renders
    const updateThreats = (newThreats) => {
        foundThreatsRef.current = newThreats;
        setFoundThreats(newThreats);
    };

    return (
        <div className="scanning-page" style={{ minHeight: '100vh', overflowY: 'auto' }}>
            {error ? (
                <div className="error-message">
                    <p>{error}</p>
                    <button onClick={() => navigate(-1)}>Go Back</button>
                </div>
            ) : (
                <>
                    <div className="scanning-header" ref={headerRef}>
                        <h1>Scanning Data Brokers for:</h1>
                        <h2>
                            {capitalizedFirstName} {capitalizedLastName}
                        </h2>
                        <p>Searching {peopleSearchSites.length} websites for your personal information</p>
                    </div>

                    <div className="scan-status-display">
                        <div className="current-stage">
                            <FaSearch className="stage-icon" />
                            <h3 className="stage-message">{scanStages[currentStageIndex].messages[currentMessageIndex]}</h3>
                        </div>
                    </div>

                    <div className="threats-section">
                        <h3>
                            <FaInfoCircle className="info-icon" />
                            Potential Exposures
                            <span className="exposure-count">{foundThreats.length} identified</span>
                        </h3>
                        <div className="threats-list">
                            {foundThreats.map((threat, index) => {
                                const { siteName, category } = threat;
                                const { icon, siteName: obfuscatedName, category: siteCategory, details } = formatThreatMessage(siteName, category);
                                return (
                                    <div key={index} className="exposure-item">
                                        <div className="exposure-top">
                                            <div className="exposure-icon">{icon}</div>
                                            <div className="exposure-content">
                                                <div className="exposure-header">
                                                    <span className="site-name">{obfuscatedName}</span>
                                                    <span className="exposure-category">{siteCategory}</span>
                                                </div>
                                                <div className="exposure-details">{details}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <ScreenshotSection currentScreenshot={currentScreenshot} />

                    <div className="info-box-bottom">
                        <FaInfoCircle className="info-icon-bottom" />
                        <p>
                            This is a quick free scan. With limited information, we cannot fully locate or remove all your
                            personal data from the internet. To take full control of your data, sign up for a{' '}
                            <strong>free trial</strong> and get your data removed.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default ScanningPage;
