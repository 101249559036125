export const fetchMatchProbability = async (firstName, lastName, city, country) => {
    // Helper function to capitalize the first letter
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Validate that inputs are strings
    if (typeof firstName !== 'string' || typeof lastName !== 'string' ||
        typeof city !== 'string' || typeof country !== 'string') {
        console.error('Invalid input types.');
        return 0;
    }

    const capitalizedFirstName = capitalizeFirstLetter(firstName.trim());
    const capitalizedLastName = capitalizeFirstLetter(lastName.trim());
    const capitalizedCity = capitalizeFirstLetter(city.trim());
    const capitalizedCountry = capitalizeFirstLetter(country.trim());

    // Construct the full name and location strings
    const fullName = `${capitalizedFirstName} ${capitalizedLastName}`;
    const locationStr = `${capitalizedCity}, ${capitalizedCountry}`;

    // Helper function to query GPT
    const queryGPT = async () => {
        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "system",
                            content: `You are an expert in data validation and risk analysis. Your tasks based on the user's input are:
                            
1. **Name Validation**:
    - Determine if the provided first and last names are common and likely to be real.
    - Flag any unusual, improbable, or syntactically incorrect name combinations (e.g., names containing numbers or special characters).

2. **Location Validation**:
    - Assess if the city and country combination is plausible and exists.
    - Identify any inconsistencies or errors in the location data (e.g., misspelled city or country names).

3. **Risk Scoring**:
    - Based on the above validations and additional factors like name uniqueness, location privacy laws, and likely digital presence, assign a risk score from 0 to 5 where:
        - 0 = Fake or highly improbable name/location combination
        - 1 = Low risk
        - 2 = Low-moderate risk
        - 3 = Moderate risk
        - 4 = Moderate-high risk
        - 5 = High risk
    - **Important:** If the input is determined to be fake or highly improbable, the risk score **must** be 0.

**Return only the risk score as a single number without any additional text. Ensure that any fake or invalid inputs are assigned a risk score of 0.**
                        `
                        },
                        {
                            role: "user",
                            content: `First Name: ${capitalizedFirstName}\nLast Name: ${capitalizedLastName}\nLocation: ${locationStr}`
                        }
                    ],
                    max_tokens: 10,
                })
            });

            if (!response.ok) throw new Error(`Error: ${response.statusText}`);

            const data = await response.json();
            let riskScore = parseFloat(data.choices[0].message.content.trim());

            // Ensure the risk score is within 0-5
            if (isNaN(riskScore) || riskScore < 0) riskScore = 0;
            if (riskScore > 5) riskScore = 5;

            return Math.round(riskScore);
        } catch (error) {
            console.error('Error fetching risk score:', error);
            return 3; // Default to medium risk on error
        }
    };

    // Query GPT with the constructed prompt
    const riskScore = await queryGPT();

    // Define match ranges based on risk score
    const baseMatches = {
        5: { min: 35, max: 45 },  // High risk
        4: { min: 25, max: 35 },  // Moderate-high risk
        3: { min: 15, max: 25 },  // Moderate risk
        2: { min: 8, max: 15 },   // Low-moderate risk
        1: { min: 3, max: 8 },    // Low risk
        0: { min: 0, max: 0 }     // Minimal risk (No matches)
    };

    const { min, max } = baseMatches[riskScore] || baseMatches[3];
    const totalMatches = riskScore === 0 ? 0 : Math.floor(Math.random() * (max - min + 1)) + min;
    const totalSites = 208;
    const threatPercentage = ((totalMatches / totalSites) * 100).toFixed(2); // Returns a string with two decimal places

    console.log({
        name: fullName,
        location: locationStr,
        riskScore,
        totalMatches,
        threatPercentage: `${threatPercentage}%`
    });

    return parseFloat(threatPercentage); // Return as a float
};
