import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../components/PaymentForm';
import '../components/PaymentForm.css';

// Load Stripe publishable key based on the environment mode
const stripePublishableKey = process.env.REACT_APP_MODE === 'live' 
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE 
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;
const stripePromise = loadStripe(stripePublishableKey);

const PaymentPage = () => (
    <div className="payment-container">
        <Elements stripe={stripePromise}>
            <PaymentForm amount={17995} /> {/* Amount in cents */}
        </Elements>
    </div>
);

export default PaymentPage;
