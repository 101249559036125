import axios from 'axios';

// !!!IMPORTANT!!! DO NOT CHANGE THIS URL
// Login server is separate from the dashboard API server
// Login server must remain at: https://cleandata.fly.dev
const AUTH_SERVER_URL = 'https://cleandata.fly.dev';

const api = axios.create({
    baseURL: AUTH_SERVER_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor to include token in headers
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const login = async (email, password) => {
    try {
        const response = await api.post('https://cleandata.fly.dev/api/login', { email, password });
        
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            return {
                user: response.data.user,
                token: response.data.token
            };
        } else {
            throw new Error('No token received from server');
        }
    } catch (error) {
        console.error('Login error:', error.response?.data || error.message);
        throw error;
    }
};

export const getUser = async () => {
    try {
        const response = await api.get('https://cleandata.fly.dev/api/user-data');
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        return null;
    }
};

export const logout = async () => {
    try {
        localStorage.removeItem('token');
    } catch (error) {
        console.error('Logout error:', error);
        localStorage.removeItem('token');
    }
};

export default api;
