import React, { useState } from 'react';
import { FaShieldAlt, FaCheck, FaLock } from 'react-icons/fa';
import './ChangePlanPage.css';

const ChangePlanPage = () => {
    const [isAnnually, setIsAnnually] = useState(true);

    const handleToggle = () => {
        setIsAnnually(!isAnnually);
    };

    return (
        <div className="change-plan-page">
            <div className="plan-header">
                <h1>Choose Your Protection Level</h1>
                <p>Select the plan that best fits your privacy needs</p>
            </div>

            <div className="billing-toggle">
                <span className={!isAnnually ? 'active' : ''}>Monthly</span>
                <label className="toggle-switch">
                    <input 
                        type="checkbox" 
                        checked={isAnnually} 
                        onChange={handleToggle}
                    />
                    <span className="slider"></span>
                </label>
                <span className={isAnnually ? 'active' : ''}>
                    Annually <span className="save-badge">Save 30%</span>
                </span>
            </div>

            <div className="plans-container">
                <div className="plan-card">
                    <div className="plan-header">
                        <FaShieldAlt className="plan-icon" />
                        <h2>Basic</h2>
                        <div className="price">
                            <span className="amount">${isAnnually ? '8' : '11'}</span>
                            <span className="period">/mo</span>
                        </div>
                        <p className="billed-text">
                            {isAnnually ? 'Billed $96 annually' : 'Billed monthly'}
                        </p>
                    </div>
                    <ul className="features-list">
                        <li><FaCheck /> Remove from 100+ data brokers</li>
                        <li><FaCheck /> Monthly monitoring</li>
                        <li><FaCheck /> Basic support</li>
                    </ul>
                    <button className="select-plan-button">Select Basic</button>
                </div>

                <div className="plan-card featured">
                    <div className="popular-badge">Most Popular</div>
                    <div className="plan-header">
                        <FaShieldAlt className="plan-icon" />
                        <h2>Pro</h2>
                        <div className="price">
                            <span className="amount">${isAnnually ? '11' : '15'}</span>
                            <span className="period">/mo</span>
                        </div>
                        <p className="billed-text">
                            {isAnnually ? 'Billed $132 annually' : 'Billed monthly'}
                        </p>
                    </div>
                    <ul className="features-list">
                        <li><FaCheck /> Remove from 200+ data brokers</li>
                        <li><FaCheck /> Weekly monitoring</li>
                        <li><FaCheck /> Priority support</li>
                        <li><FaCheck /> Custom removal requests</li>
                    </ul>
                    <button className="select-plan-button featured">Select Pro</button>
                </div>

                <div className="plan-card">
                    <div className="plan-header">
                        <FaShieldAlt className="plan-icon" />
                        <h2>Enterprise</h2>
                        <div className="price">
                            <span className="amount">${isAnnually ? '15' : '20'}</span>
                            <span className="period">/mo</span>
                        </div>
                        <p className="billed-text">
                            {isAnnually ? 'Billed $180 annually' : 'Billed monthly'}
                        </p>
                    </div>
                    <ul className="features-list">
                        <li><FaCheck /> Remove from 300+ data brokers</li>
                        <li><FaCheck /> Daily monitoring</li>
                        <li><FaCheck /> 24/7 premium support</li>
                        <li><FaCheck /> Advanced protection features</li>
                        <li><FaCheck /> Team management</li>
                    </ul>
                    <button className="select-plan-button">Select Enterprise</button>
                </div>
            </div>
        </div>
    );
};

export default ChangePlanPage;
