// peopleSearchSites.js
const peopleSearchSites = [
    "24counter.com",
    "411.com",
    "411.info",
    "411locate.com",
    "absolutepeoplesearch.com",
    "addressbook.com",
    "addresses.com",
    "advanced-people-search.com",
    "advancedbackgroundchecks.com",
    "affordablebackgroundchecks.com",
    "allareacodes.com",
    "allpeople.com",
    "americaphonebook.com",
    "anywho.com",
    "arivify.com",
    "arrestfacts.com",
    "backgroundalert.com",
    "backgroundcheck.run",
    "backgroundcheckers.net",
    "beenverified.com",
    "bumper.com",
    "callercenter.com",
    "cellrevealer.com",
    "centeda.com",
    "checksecrets.com",
    "clicksearch.us",
    "clubset.com",
    "clustrmaps.com",
    "confidentialphonelookup.com",
    "corporationwiki.com",
    "councilon.com",
    "courtcasefinder.com",
    "criminalpages.com",
    "criminalregistry.org",
    "cubib.com",
    "curadvisor.com",
    "cyberbackgroundchecks.com",
    "dataveria.com",
    "familytreenow.com",
    "fastbackgroundcheck.com",
    "fastpeoplesearch.com",
    "findpeoplesearch.com",
    "findrec.com",
    "floridaresidentsdirectory.com",
    "freebackgroundcheck.org",
    "freepeopledirectory.com",
    "gladiknow.com",
    "golookup.com",
    "govwarrantsearch.org",
    "hodges-directory.us",
    "homemetry.com",
    "hpcc-usa.org",
    "idstrong.com",
    "idtrue.com",
    "information.com",
    "inforver.com",
    "infotracer.com",
    "inmatessearcher.com",
    "instantcheckmate.com",
    "intelius.com",
    "knowthycustomer.com",
    "kwold.com",
    "mashpanel.com",
    "mugshotlook.com",
    "mylife.com",
    "neighbor.report",
    "neighborwho.com",
    "newenglandfacts.com",
    "northcarolinaresidentdatabase.com",
    "numberguru.com",
    "numberville.com",
    "nuwber.com",
    "officialusa.com",
    "ohioresidentdatabase.com",
    "oldphonebook.com",
    "onlinesearches.com",
    "ownerly.com",
    "peekyou.com",
    "people-background-check.com",
    "people-searchers.com",
    "people-wizard.com",
    "people.yellowpages.com",
    "peoplebyname.com",
    "peoplebyphone.com",
    "peoplefinders.com",
    "peoplelooker.com",
    "peoplesearch.com",
    "peoplesearch123.com",
    "peoplesearcher.com",
    "peoplesearchexpert.com",
    "peoplesearchnow.com",
    "peoplesearchusa.org",
    "peoplesmart.com",
    "peopleswhized.com",
    "peopleswhizr.com",
    "peopleswiz.com",
    "peopleswizard.com",
    "peoplewhiz.com",
    "peoplewhiz.net",
    "peoplewhized.com",
    "peoplewhized.net",
    "peoplewhizr.com",
    "peoplewhizr.net",
    "peoplewin.com",
    "peoplewiz.com",
    "peoplewizard.net",
    "peoplewizr.com",
    "personsearchers.com",
    "persontrust.com",
    "phonebooks.com",
    "plcom.net",
    "privateeye.com",
    "privatenumberchecker.com",
    "privaterecords.net",
    "privatereports.com",
    "propeoplesearch.com",
    "pub360.com",
    "publicdatacheck.com",
    "publicdatadigger.com",
    "publicdatausa.com",
    "publicinfoservices.com",
    "publicrecordsnow.com",
    "publicreports.com",
    "publicsearcher.com",
    "quickpeopletrace.com",
    "radaris.com",
    "recordsfinder.com",
    "rehold.com",
    "revealname.com",
    "reversephonecheck.com",
    "reversephonelookup.com",
    "sealedrecords.net",
    "searchpeoplefree.com",
    "searchpublicrecords.com",
    "searchquarry.com",
    "searchsystems.net",
    "secretinfo.org",
    "smartbackgroundchecks.com",
    "socialcatfish.com",
    "spokeo.com",
    "spyfly.com",
    "staterecords.org",
    "telephonedirectories.us",
    "tennhelp.com",
    "texasarrests.org",
    "texasarrestwarrants.org",
    "texaswarrantroundup.org",
    "thatsthem.com",
    "thisnumber.com",
    "topwa.org",
    "truepeoplesearch.com",
    "trustifo.com",
    "truthfinder.com",
    "truthrecord.org",
    "unitedstatesphonebook.com",
    "usa-people-search.com",
    "usapeopledata.com",
    "usatrace.com",
    "usphonebook.com",
    "usphonepro.com",
    "usrecords.net",
    "ussearch.com",
    "uswarrants.org",
    "verecor.com",
    "vericora.com",
    "veriforia.com",
    "verifyarrestwarrant.com",
    "verifythem.org",
    "veripages.com",
    "virtory.com",
    "weinform.org",
    "wellnut.com",
    "whitepages.com",
    "yellowbook.com",
    "yellowpagesdirectory.com",
    "zabasearch.com",
    "addresssearch.com",
    "backgroundchecks.org",
    "backgroundrecords.com",
    "dobsearch.com",
    "easybackgroundchecks.com",
    "fastpeoplesearch.info",
    "freebackgroundcheck.io",
    "freebackgroundchecks.com",
    "freepeoplescan.com",
    "freepeoplesearch.io",
    "freephonetracer.com",
    "govarrestrecords.org",
    "governmentregistry.org",
    "onlinepeoplesearch.com",
    "oregonpublicrecords.org",
    "peoplefinder.com",
    "profile.systems",
    "publicrecordcenter.com",
    "publicrecords.com",
    "publicrecords.searchsystems.net",
    "publicrecords.site",
    "publicrecordscenter.org",
    "publicrecordsofficial.com",
    "publicrecordsreviews.com",
    "publicsrecords.com",
    "sheriffsdepartment.net",
    "snoopstation.com",
    "top4backgroundchecks.com",
    "truepeoplesearch.info",
    "truepeoplesearch.io",
    "truthviewer.com",
    "usfriendsreunited.com"
];

export default peopleSearchSites;
