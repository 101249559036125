import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './SuccessPage.css';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, setUserFromToken } = useAuth();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const fetchSessionData = async () => {
      const sessionId = new URLSearchParams(location.search).get('session_id');
      console.log('Session ID:', sessionId);

      if (!sessionId) {
        setError('No session ID found. Please try again or contact support.');
        setProcessing(false);
        return;
      }

      try {
        // Use the cleandata.fly.dev server for payments
        const apiUrl = 'https://cleandata.fly.dev';
        const endpoint = `/api/payment/session/${sessionId}`;
        const fullUrl = `${apiUrl}${endpoint}`;
        
        console.log('Fetching from:', fullUrl);
        
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`Server error: ${errorText}`);
        }

        const data = await response.json();
        console.log('Session data received:', data);

        if (data.success && data.token) {
          // Store token in localStorage first
          localStorage.setItem('token', data.token);
          // Then update context
          await setUserFromToken(data.token);
          // Navigate after token is set
          navigate('/edit-info');
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
        setError(`Payment verification failed: ${error.message}`);
      } finally {
        setProcessing(false);
      }
    };

    fetchSessionData();
  }, [location.search, navigate, setUserFromToken]);

  if (processing) {
    return (
      <div className="success-container">
        <h2 className="success-title">Processing Payment</h2>
        <p className="success-message">Please wait while we verify your payment...</p>
      </div>
    );
  }

  return (
    <div className="success-container">
      {error ? (
        <>
          <h2 className="success-title">Payment Status</h2>
          <p className="error-message">{error}</p>
          <div className="button-container">
            <button
              onClick={() => navigate('/edit-info')}
              className="primary-button"
            >
              Continue to Edit Info
            </button>
            <button
              onClick={() => navigate('/')}
              className="secondary-button"
            >
              Return Home
            </button>
          </div>
        </>
      ) : (
        <div>
          <h2 className="success-title">Payment Successful!</h2>
          <p className="success-message">Redirecting to edit info page...</p>
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
