// src/pages/ResultsPage.jsx

import React, { useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ResultsPage.css';
import { 
    FaLock, 
    FaExclamationTriangle, 
    FaShieldAlt, 
    FaCheck, 
    FaUserShield,
    FaGlobe,
    FaSearch,
    FaDatabase
} from 'react-icons/fa';

// Add this utility function at the top of the file
const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Add these constants at the top of the file
const RISK_LEVELS = {
    HIGH: { label: 'High', color: '#ff4444', weight: 60 }, // 60% chance
    MEDIUM: { label: 'Medium', color: '#ffbb33', weight: 30 }, // 30% chance
    LOW: { label: 'Low', color: '#00C851', weight: 10 } // 10% chance
};

// Add this function to get random risk level
const getRandomRiskLevel = () => {
    const random = Math.random() * 100;
    if (random < RISK_LEVELS.LOW.weight) {
        return RISK_LEVELS.LOW;
    } else if (random < RISK_LEVELS.LOW.weight + RISK_LEVELS.MEDIUM.weight) {
        return RISK_LEVELS.MEDIUM;
    }
    return RISK_LEVELS.HIGH;
};

const ResultsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const scrollRef = useRef(0);

    // Destructure with proper fallback values
    const { 
        threats = [], 
        firstName = '',
        lastName = '',
        totalMatches = 0,
        riskScore = 0,
        threatPercentage = '0%'
    } = location.state || {};

    // Add a console.log to help debug
    useEffect(() => {
        console.log('Results Page State:', {
            threatsLength: threats.length,
            totalMatches,
            categoryDistribution: getCategoryCounts(threats)
        });
    }, [threats, totalMatches]);

    useEffect(() => {
        if (!location.state) {
            console.error('Missing required scan data');
            navigate('/', { replace: true });
        }
    }, [location.state, navigate]);

    // Redirect after 30 seconds instead of 5
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            window.location.href = 'https://cleandata.me/pricing';
        }, 30000);

        return () => clearTimeout(redirectTimeout);
    }, []);

    const formatThreatMessage = (siteName, category, details) => {
        const icons = {
            'Public Records': <FaDatabase className="threat-icon" />,
            'Address History': <FaGlobe className="threat-icon" />,
            'Contact Info': <FaSearch className="threat-icon" />,
            'Social Media': <FaUserShield className="threat-icon" />,
            'Background Check': <FaExclamationTriangle className="threat-icon" />,
            'People Search': <FaSearch className="threat-icon" />
        };

        return {
            icon: icons[category] || <FaExclamationTriangle className="threat-icon" />,
            details: details || `Personal information exposed on ${siteName}`
        };
    };

    // Add this function to count threats by category
    const getCategoryCounts = (threats) => {
        const counts = threats.reduce((acc, threat) => {
            acc[threat.category] = (acc[threat.category] || 0) + 1;
            return acc;
        }, {});
        return counts;
    };

    const capitalizedFirstName = capitalizeFirstLetter(firstName);
    const capitalizedLastName = capitalizeFirstLetter(lastName);
    const categoryCounts = getCategoryCounts(threats);

    // Optimize scroll handling
    const handleScroll = useCallback((e) => {
        scrollRef.current = window.scrollY;
        // Prevent default only if needed
        if (document.activeElement?.tagName === 'INPUT') {
            e.preventDefault();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: false });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div className="results-page" style={{ minHeight: '100vh', overflowY: 'auto' }}>
            <div className="results-header">
                <FaExclamationTriangle className="warning-icon" />
                <h1>Data Exposure Report</h1>
                <h2>{threats.length} Privacy Threats Detected</h2>
                <p>Personal information for {capitalizedFirstName} {capitalizedLastName} is currently exposed</p>
            </div>

            <div className="exposure-summary">
                <div className="stat-box">
                    <FaGlobe className="stat-icon" />
                    <div className="stat-content">
                        <h3>{totalMatches}</h3>
                        <p>Data Brokers</p>
                    </div>
                </div>
                <div className="stat-box">
                    <FaShieldAlt className="stat-icon" />
                    <div className="stat-content">
                        <h3>High Risk</h3>
                        <p>Exposure Level</p>
                    </div>
                </div>
                <div className="stat-box">
                    <FaUserShield className="stat-icon" />
                    <div className="stat-content">
                        <h3>Immediate</h3>
                        <p>Action Needed</p>
                    </div>
                </div>
            </div>

            <div className="category-breakdown">
                <h3>Estimated Exposures by Category:</h3>
                <div className="category-grid">
                    {Object.entries(categoryCounts).map(([category, count]) => (
                        <div key={category} className="category-item">
                            <div className="category-icon">
                                {formatThreatMessage('', category).icon}
                            </div>
                            <div className="category-content">
                                <h4>{category}</h4>
                                <p>{count} sites detected</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="threats-container">
                <h2>
                    <FaExclamationTriangle className="section-icon" />
                    Detected Exposures
                </h2>
                <div className="threats-list">
                    {threats.map((threat, index) => {
                        const { icon, details } = formatThreatMessage(
                            threat.siteName,
                            threat.category,
                            threat.details
                        );
                        const riskLevel = getRandomRiskLevel();
                        return (
                            <div key={index} className="exposure-item">
                                <div className="exposure-icon">{icon}</div>
                                <div className="exposure-content">
                                    <div className="exposure-header">
                                        <span className="site-name">{threat.siteName}</span>
                                        <span className="exposure-category">{threat.category}</span>
                                    </div>
                                    <div className="exposure-details">
                                        <p>{details}</p>
                                        <p className="risk-level">
                                            Risk Level: <span style={{ color: riskLevel.color }}>{riskLevel.label}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="action-steps">
                <h2>Recommended Actions</h2>
                <div className="steps-container">
                    <div className="step">
                        <FaShieldAlt className="step-icon" />
                        <h3>Start Your Protection</h3>
                        <p>Begin your free trial to remove your data from these sites</p>
                    </div>
                    <div className="step">
                        <FaCheck className="step-icon" />
                        <h3>Automated Removal</h3>
                        <p>We'll automatically submit removal requests to all data brokers</p>
                    </div>
                    <div className="step">
                        <FaLock className="step-icon" />
                        <h3>Ongoing Protection</h3>
                        <p>Get continuous monitoring and removal of your personal data</p>
                    </div>
                </div>
            </div>

            <div className="cta-container">
                <h2>Take Control of Your Privacy</h2>
                <p>Start your free trial now to remove your personal information from these sites</p>
                <button 
                    className="cta-button"
                    onClick={() => window.location.href = 'https://cleandata.me/pricing'}
                >
                    Start Free Trial
                </button>
                <p className="guarantee">
                    <FaShieldAlt /> 30-Day Money-Back Guarantee
                </p>
            </div>

            <div className="redirect-notice">
                <FaShieldAlt className="loading-icon spinning" />
                <p>Preparing your privacy protection plan...</p>
            </div>
        </div>
    );
};

export default ResultsPage;
