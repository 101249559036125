import React from 'react';
import FAQComponent from '../components/FAQComponent';
import { Footer } from '../components/footer';
import { useAuth } from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavBar from '../components/MobileNavbar';
import { FaPhone, FaEnvelope, FaQuestionCircle } from 'react-icons/fa';
import './SupportPage.css';

const SupportPage = () => {
    const { isAuthenticated } = useAuth();
    const isMobile = window.innerWidth <= 1100; // Using the global breakpoint

    return (
        <>
            {isAuthenticated && (isMobile ? <MobileNavBar /> : <Navbar />)}
            <div className="support-page">
                {isAuthenticated && !isMobile && <Sidebar />}
                <div className={`content-wrapper ${!isAuthenticated ? 'full-screen' : ''}`}>
                    <div className="support-main">
                        <header className="support-header">
                            <div className="header-content">
                                <FaQuestionCircle className="support-icon" />
                                <h1>Support Center</h1>
                                <p>We're here to help protect your digital footprint</p>
                            </div>
                            <div className="cyber-line"></div>
                        </header>

                        <div className="support-content">
                            <div className="faq-section">
                                <div className="section-header">
                                    <h2>Frequently Asked Questions</h2>
                                    <div className="cyber-line-small"></div>
                                </div>
                                <div className="faq-card">
                                    <FAQComponent />
                                </div>
                            </div>
                            
                            <div className="contact-section">
                                <div className="section-header">
                                    <h2>Need More Help?</h2>
                                    <div className="cyber-line-small"></div>
                                </div>
                                <div className="contact-options">
                                    <div className="contact-card">
                                        <FaPhone className="contact-icon" />
                                        <h3>Call Us</h3>
                                        <p>+1 (424) 216-9253</p>
                                        <div className="card-glow"></div>
                                    </div>
                                    <div className="contact-card">
                                        <FaEnvelope className="contact-icon" />
                                        <h3>Email Us</h3>
                                        <p>support@cleandata.me</p>
                                        <div className="card-glow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SupportPage;
