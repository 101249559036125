// DashboardHeader.js

import React from 'react';
import PropTypes from 'prop-types';
import { FaUserCog, FaShieldAlt } from 'react-icons/fa';
import './DashboardHeader.css';

const DashboardHeader = ({ currentTask, dataBrokers = [], isScanning }) => {
    const getNextStepMessage = () => {
        if (isScanning) {
            return currentTask;
        }

        // Find brokers with different statuses to determine next steps
        const pendingBrokers = dataBrokers.filter(broker => 
            broker.status.includes('Processing') || 
            broker.status.includes('Sent')
        );
        const verificationBrokers = dataBrokers.filter(broker => 
            broker.status.includes('Verification')
        );
        const confirmationBrokers = dataBrokers.filter(broker => 
            broker.status.includes('Progress')
        );

        if (pendingBrokers.length > 0) {
            return `Sending Removal Request To: ${pendingBrokers[0].name}`;
        }
        if (verificationBrokers.length > 0) {
            return `Awaiting Verification From: ${verificationBrokers[0].name}`;
        }
        if (confirmationBrokers.length > 0) {
            return `Scanning For Confirmation From: ${confirmationBrokers[0].name}`;
        }
        
        return 'Monitoring removal status across all data brokers...';
    };

    return (
        <header className="dashboard-header">
            <div className="header-content">
                <h1 className="header-title">
                    Your Dashboard
                    <span className="title-accent"></span>
                </h1>
                <div className="task-container">
                    <div className="task-indicator"></div>
                    <p className="dashboard-task">
                        <span className="task-label">Current Task:</span>
                        <span className="task-text">{getNextStepMessage()}</span>
                    </p>
                </div>
                <div className="header-buttons">
                    <a href="/edit-info" className="button-link">
                        <button className="manage-profile-button">
                            <FaUserCog className="button-icon" />
                            <span>Manage Profile</span>
                            <div className="button-glow"></div>
                        </button>
                    </a>
                    <a href="/data-removals" className="button-link">
                        <button className="view-removals-button">
                            <FaShieldAlt className="button-icon" />
                            <span>View Removals</span>
                            <div className="button-glow"></div>
                        </button>
                    </a>
                </div>
            </div>
            <div className="header-background">
                <div className="cyber-grid"></div>
            </div>
        </header>
    );
};

DashboardHeader.propTypes = {
    currentTask: PropTypes.string,
    dataBrokers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.string
    })),
    isScanning: PropTypes.bool
};

export default DashboardHeader;
