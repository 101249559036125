import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import './FeatureToggles.css';

const FeatureToggles = () => {
    // Define the toggles you want to display with their display names and order
    const availableToggles = {
        data_leak_notification: {
            label: 'Data Leak Notification',
            order: 1
        },
        monitoring_verification: {
            label: 'Monitoring Verification',
            order: 2
        },
        multi_factor_auth: {
            label: 'Multi Factor Auth',
            order: 3
        },
        role_based_access: {
            label: 'Role Based Access',
            order: 4
        }
    };
    
    const [toggles, setToggles] = useState({});
    const [error, setError] = useState(null);

    // Define the API URL based on environment
    const API_URL = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:5001/api/feature-toggles'
        : 'https://dashboard-api-server.fly.dev/api/feature-toggles';

    const FETCH_URL = `${API_URL}`;
    const UPDATE_URL = `${API_URL}/update`;

    const fetchToggles = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(FETCH_URL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setToggles(data);
            return data;
        } catch (error) {
            console.error('[FeatureToggles] Error fetching toggles:', error);
            // Set default toggles in case of error
            const defaultToggles = {
                multi_factor_auth: false,
                role_based_access: false,
                monitoring_verification: false,
                data_leak_notification: false
            };
            setToggles(defaultToggles);
            return defaultToggles;
        }
    };

    const handleToggleChange = async (toggleName, newValue) => {
        console.log('[FeatureToggles] Updating toggle:', { toggleName, newValue });
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(UPDATE_URL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ toggleName, value: newValue })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('[FeatureToggles] Toggle updated successfully:', data);
            
            // Update the local state with the response data
            if (data.success && data.featureToggles) {
                setToggles({
                    ...toggles,
                    [toggleName]: data.featureToggles[toggleName]
                });
                setError(null);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error(`[FeatureToggles] Failed to update toggle ${toggleName}:`, error);
            setError('Failed to update feature toggle');
            // Revert the toggle state since the update failed
            setToggles(prevToggles => ({
                ...prevToggles,
                [toggleName]: !newValue
            }));
        }
    };

    useEffect(() => {
        fetchToggles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="feature-toggles">
            <h3>Feature Settings</h3>
            {error && <div className="error-message">{error}</div>}
            <div className="toggle-list">
                {Object.entries(availableToggles)
                    .sort((a, b) => a[1].order - b[1].order)
                    .map(([key]) => (
                        <div 
                            key={key} 
                            className={`toggle-item ${toggles[key] ? 'active' : ''}`}
                        >
                            <span className="toggle-label">
                                {availableToggles[key].label}
                            </span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={toggles[key] || false}
                                    onChange={() => handleToggleChange(key, !toggles[key])}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default FeatureToggles;
