import React, { useState } from "react";
import {
  FaEraser,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import "./footer.css"; // Import the CSS file

export function Footer() {
  const [hoveredLink, setHoveredLink] = useState(null);

  // Updated URLs
  const links = {
    aboutUs: "https://cleandata.me/about",
    careers: "https://cleandata.me/contact",
    privacyPolicy: "https://cleandata.me/privacy-policy",
    termsOfService: "http://www.cleandata.me/tos",
    contactUs: "https://cleandata.me/contact",
    facebook: "https://cleandata.me/",
    twitter: "https://x.com/CleanData_me",
    linkedin: "https://cleandata.me/",
    instagram: "https://cleandata.me/",
  };

  return (
    <footer className="footer">
      {/* Logo Section */}
      <a href={links.aboutUs} className="footer-logo">
        <FaEraser className="logo-icon" />
        <span className="logo-title">
          CleanData<span className="logo-subtitle">.me</span>
        </span>
      </a>

      <div className="footer-content">
        {/* Company Info */}
        <div className="footer-column">
          <h4 className="footer-heading">About Us</h4>
          <p className="footer-description">
            Protecting your data is our top priority. We offer cutting-edge
            security solutions to keep your information safe and secure.
          </p>
        </div>

        {/* Quick Links */}
        <div className="footer-column">
          <h4 className="footer-heading">Quick Links</h4>
          <nav>
            {[
              { name: "About Us", link: links.aboutUs },
              { name: "Careers", link: links.careers },
              { name: "Privacy Policy", link: links.privacyPolicy },
              { name: "Terms of Service", link: links.termsOfService },
              { name: "Contact Us", link: links.contactUs },
            ].map((item) => (
              <a
                key={item.name}
                href={item.link}
                className={`footer-link ${
                  hoveredLink === item.name ? "hover" : ""
                }`}
                onMouseEnter={() => setHoveredLink(item.name)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {item.name}
              </a>
            ))}
          </nav>
        </div>

        {/* Social Media Links */}
        <div className="footer-column">
          <h4 className="footer-heading">Follow Us</h4>
          <div className="footer-socials">
            {[
              {
                icon: <FaFacebookF />,
                link: links.facebook,
                name: "Facebook",
              },
              {
                icon: <FaTwitter />,
                link: links.twitter,
                name: "Twitter",
              },
              {
                icon: <FaLinkedinIn />,
                link: links.linkedin,
                name: "LinkedIn",
              },
              {
                icon: <FaInstagram />,
                link: links.instagram,
                name: "Instagram",
              },
            ].map((social) => (
              <a
                key={social.name}
                href={social.link}
                className={`social-icon ${
                  hoveredLink === social.name ? "hover" : ""
                }`}
                onMouseEnter={() => setHoveredLink(social.name)}
                onMouseLeave={() => setHoveredLink(null)}
                aria-label={social.name}
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        © {new Date().getFullYear()} CleanData. All rights reserved.
      </div>
    </footer>
  );
}
