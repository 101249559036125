import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/authService';
import { socket } from '../socket';
import LoadingSpinner from '../components/LoadingSpinner';
import UserInfo from '../components/UserInfo';
import DashboardStats from '../components/DashboardStats';
import FeatureToggles from '../components/FeatureToggles';
import DataBrokerListComponent from '../components/DataBrokerListComponent';
import DataPointsComponent from '../components/DataPointsComponent';
import ErrorFallback from '../components/ErrorFallback';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavBar from '../components/MobileNavbar';
import { Footer } from "../components/footer";
import { formatDataBrokerList, getScreenshotUrl } from '../utils';
import './Dashboard.css';
import ImagePreview from '../components/ImagePreview';

const Dashboard = () => {
    const { user, token } = useAuth();
    const [dashboardData, setDashboardData] = useState(initialDashboardState);
    const [dataBrokerList, setDataBrokerList] = useState(initialDataBrokers);
    const [liveSearchItems, setLiveSearchItems] = useState([
        { 
            url: `https://www.fastpeoplesearch.com/name/${user?.firstName}-${user?.lastName}`, 
            siteName: 'FastPeopleSearch' 
        },
        { 
            url: `https://www.truepeoplesearch.com/results?name=${user?.firstName}%20${user?.lastName}`, 
            siteName: 'TruePeopleSearch' 
        }
    ]);
    const [breaches, setBreaches] = useState([]);
    const [breachLoading, setBreachLoading] = useState(true);
    const [breachErrorMessage, setBreachErrorMessage] = useState("");
    const [showMoreBreaches, setShowMoreBreaches] = useState(false);

    const dashboardRef = useRef(null);

    // Fetch Dashboard Data from API
    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await api.get('/dashboard');
                setDashboardData(response.data);
                setBreachLoading(false);
                setBreaches(response.data.breaches || []);
            } catch (error) {
                setBreachErrorMessage(error.response?.data?.error || 'Failed to fetch dashboard data');
                setBreachLoading(false);
            }
        };

        if (user && token) {
            fetchDashboardData();
        }
    }, [user, token]);

    // Trigger scroll on initial load and when user data is available
    useEffect(() => {
        if (user) {  // Only scroll when user data is available
            const timer = setTimeout(() => {
                if (dashboardRef?.current && document.activeElement?.tagName !== 'INPUT' && document.activeElement?.tagName !== 'TEXTAREA') {
                    dashboardRef.current.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 500); // Increased timeout to ensure content is loaded

            return () => clearTimeout(timer);
        }
    }, [user]); // Add user as dependency to ensure scroll happens after data is loaded

    // Integrate WebSocket for real-time updates
    useEffect(() => {
        if (socket && user) {
            // Join the user's room
            socket.emit('join', { userId: user.id });

            // Listen for scan progress updates
            socket.on('scan_progress', (updateData) => {
                setDashboardData((prevData) => ({
                    ...prevData,
                    stats: {
                        ...prevData.stats,
                        progress: updateData.progress,
                        sitesScanned: updateData.sitesScanned,
                        profilesFound: updateData.profilesFound,
                        totalMatches: updateData.totalMatches,
                        // Update other stats as needed
                    },
                }));
            });

            // Listen for scan completion
            socket.on('scan_complete', (completeData) => {
                setDashboardData((prevData) => ({
                    ...prevData,
                    stats: {
                        ...prevData.stats,
                        isScanning: false,
                        lastScanTime: completeData.lastScanTime,
                        nextScanTime: completeData.nextScanTime,
                        currentSite: null,
                    },
                }));
                // Optionally, fetch the latest breach data
                fetchLatestBreaches();
            });

            // Listen for new matches
            socket.on('match_found', (match) => {
                setDashboardData((prevData) => ({
                    ...prevData,
                    stats: {
                        ...prevData.stats,
                        totalMatches: prevData.stats.totalMatches + 1,
                    },
                }));
                // Handle the new match as needed
            });

            return () => {
                socket.off('scan_progress');
                socket.off('scan_complete');
                socket.off('match_found');
                socket.emit('leave', { userId: user.id });
            };
        }
    }, [socket, user]);

    // Function to fetch the latest breaches after scan completion
    const fetchLatestBreaches = async () => {
        try {
            const response = await api.get('/breaches', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setBreaches(response.data.breaches || []);
        } catch (error) {
            setBreachErrorMessage(error.response?.data?.error || 'Failed to fetch breach data');
        }
    };

    const getThreatLevel = (pwnCount) => {
        if (pwnCount > 10000000) return 'critical';
        if (pwnCount > 1000000) return 'high';
        if (pwnCount > 100000) return 'medium';
        return 'low';
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        return num;
    };

    return (
        <>
            {/* Navbar */}
            <Navbar />
            <MobileNavBar />

            <div className="dashboard-page">
                {/* Sidebar */}
                <Sidebar />

                <div className="content-wrapper">
                    <div className="dashboard-main">
                        <div ref={dashboardRef} className="dashboard-content">
                            {/* Dashboard Header */}
                            <DashboardHeader 
                                currentTask={dashboardData?.stats?.currentSite}
                                dataBrokers={dataBrokerList}
                                isScanning={dashboardData?.stats?.isScanning}
                            />

                            <div className="dashboard-grid">
                                {/* Stats Section */}
                                <div className="stats-section">
                                    <ErrorBoundary>
                                        <DashboardStats stats={dashboardData?.stats} />
                                    </ErrorBoundary>
                                </div>

                                {/* Data Broker Section */}
                                <div className="data-broker-section">
                                    <DataBrokerListComponent 
                                        dataBrokers={dataBrokerList}
                                        isScanning={dashboardData?.stats?.isScanning}
                                        currentSite={dashboardData?.stats?.currentSite}
                                        profilesFound={dashboardData?.stats?.profilesFound}
                                    />
                                </div>

                                {/* User Features Section */}
                                <div className="user-features-section">
                                    <ErrorBoundary>
                                        <UserInfo user={{
                                            ...user,  // Spread the authenticated user data (email, firstName, lastName, etc.)
                                            memberSince: dashboardData?.user?.memberSince,  // Keep the memberSince from dashboardData
                                            subscriptionStatus: user?.subscriptionStatus  // Use authenticated subscription status
                                        }} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <FeatureToggles 
                                            isDevelopment={false}
                                            subscriptionStatus={user?.subscriptionStatus}
                                            featureToggles={dashboardData?.featureToggles}
                                            onToggle={async (toggleName, value) => {
                                                try {
                                                    await api.put('/feature-toggles', { toggleName, value });
                                                    setDashboardData((prevData) => ({
                                                        ...prevData,
                                                        featureToggles: {
                                                            ...prevData.featureToggles,
                                                            [toggleName]: value,
                                                        },
                                                    }));
                                                } catch (error) {
                                                    console.error('Failed to update feature toggle:', error);
                                                    // Optionally, show an error message to the user
                                                }
                                            }}
                                        />
                                    </ErrorBoundary>
                                </div>

                                {/* Live Search Section */}
                                <div className="live-search-section">
                                    <h3>Live Search</h3>
                                    <div className="live-search-images">
                                        {liveSearchItems.map(item => (
                                            <ImagePreview 
                                                key={item.url}
                                                item={item}
                                                onImageClick={() => window.open(item.url, '_blank')}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Data Breach Monitor Section */}
                                <div className="breach-check-section card">
                                    <h2>Data Breach Monitor</h2>
                                    <p className="subtitle">Monitoring {user?.email} for data breaches</p>
                                    
                                    {breachErrorMessage ? (
                                        <div className="error-message">{breachErrorMessage}</div>
                                    ) : breachLoading ? (
                                        <div className="scanning-message">
                                            <div className="pulse-dot"></div>
                                            <p>Actively monitoring for data breaches...</p>
                                        </div>
                                    ) : breaches.length > 0 ? (
                                        <div className="breach-results">
                                            <div className="breach-list" id="breach-list">
                                                {breaches.slice(0, showMoreBreaches ? undefined : 4).map((breach, index) => (
                                                    <div key={index} className="breach-item">
                                                        <div className="breach-header">
                                                            <h3>{breach.Name}</h3>
                                                            <span className={`threat-level ${getThreatLevel(breach.PwnCount)}`}>
                                                                {getThreatLevel(breach.PwnCount)}
                                                            </span>
                                                        </div>
                                                        <div className="breach-details">
                                                            <p className="breach-date">
                                                                Breach date: {new Date(breach.BreachDate).toLocaleDateString()}
                                                            </p>
                                                            <p className="pwn-count">
                                                                {formatNumber(breach.PwnCount)} accounts affected
                                                            </p>
                                                            <div className="compromised-data">
                                                                <h4>Compromised Data:</h4>
                                                                <ul>
                                                                    {breach.DataClasses.slice(0, 3).map((dataClass, i) => (
                                                                        <li key={i}>{dataClass}</li>
                                                                    ))}
                                                                    {breach.DataClasses.length > 3 && (
                                                                        <li>+{breach.DataClasses.length - 3} more...</li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            
                                            {breaches.length > 4 && (
                                                <button
                                                    className="show-more-button"
                                                    onClick={() => setShowMoreBreaches(!showMoreBreaches)}
                                                    aria-expanded={showMoreBreaches}
                                                    aria-controls="breach-list"
                                                >
                                                    <span>
                                                        {showMoreBreaches 
                                                            ? "Show Less" 
                                                            : `Show ${breaches.length - 4} More Breaches`}
                                                    </span>
                                                    <div className="button-icon">
                                                        {showMoreBreaches ? "↑" : "↓"}
                                                    </div>
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="scanning-message">
                                            <div className="pulse-dot"></div>
                                            <p>Actively monitoring for data breaches...</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Dashboard;

// Initial state definitions
const initialDashboardState = {
    user: {
        email: "demo@example.com",
        firstName: "John",
        lastName: "Doe",
        memberSince: new Date().toISOString(),
        subscriptionStatus: 'Trial'
    },
    stats: {
        dataBrokerExposure: 75,
        sitesScanned: 0,
        profilesFound: 0,
        totalMatches: 0,
        progress: 0,
        isScanning: false,
        lastScanTime: null,
        nextScanTime: new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)).toISOString(),
        currentSite: null
    },
    featureToggles: {
        multi_factor_auth: false,
        role_based_access: false,
        monitoring_verification: false,
        data_leak_notification: false
    },
    breaches: [] // Added breaches to initial state
};

const initialDataBrokers = [
    { name: "Preparing scan...", status: "Getting ready" },
    { name: "Scanning data brokers...", status: "Queued" },
    { name: "Checking exposures...", status: "Queued" },
    { name: "Verifying data points...", status: "Queued" }
];

